<template>
  <v-card :loading="loading">
    <v-form @submit.prevent="submitForm">
      <v-card-title>{{ $i18n.t("forms.users.title") }}</v-card-title>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              :label="$t('forms.general.email.label')"
              v-model="form.email"
              type="email"
              :error-messages="emailErrors"
              :success="shouldAppendValidClass($v.form.email)"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              :label="$t('forms.users.timingPoints')"
              v-model="form.timingPoints"
              type="number"
              min="-99999"
              max="99999"
              :error-messages="timingPointsErrors"
              :success="shouldAppendValidClass($v.form.timingPoints)"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-alert v-if="error" dense outlined type="error">
              {{ $t(error) }}
            </v-alert>

            <v-alert v-if="success" dense outlined type="success">
              {{ $t(success) }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn type="submit" color="success" :loading="loading">
          {{ $t("forms.users.submit") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import {
  required,
  email,
  numeric,
  minValue,
  maxValue
} from "vuelidate/lib/validators";

export default {
  name: "PanelUsers",
  data() {
    return {
      form: {
        email: null,
        timingPoints: null
      },
      loading: false,
      success: null,
      error: null
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        timingPoints: {
          range: function(value) {
            if (!value) {
              return false;
            }
            const number = Number(value);
            if (isNaN(number)) {
              return false;
            }
            return number > -100000 && number < 100000 && number !== 0;
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t("forms.general.email.errors.required"));
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t("forms.general.email.errors.format"));
      return errors;
    },
    timingPointsErrors() {
      const errors = [];
      if (!this.$v.form.timingPoints.$dirty) return errors;
      !this.$v.form.timingPoints.range &&
        errors.push(this.$i18n.t("forms.users.errors.timingPoints.range"));
      return errors;
    }
  },
  methods: {
    ...mapActions({
      depositTP: "user/depositTP"
    }),
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    submitForm() {
      this.success = null;
      this.error = null;

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.loading = true;

        const payload = {
          userId: this.user.id,
          data: {
            email: this.form.email,
            timingPoints: Number(this.form.timingPoints)
          }
        };

        this.depositTP(payload)
          .then(response => {
            this.loading = false;
            this.success = "forms.users.success";
          })
          .catch(response => {
            this.loading = false;
            switch (response.data.status) {
              case -1000:
                this.error = "forms.users.errors.invalidUser";
                break;
              case -5000:
                this.error = "forms.users.errors.invalidUserType";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
          });
      }
    }
  }
};
</script>
